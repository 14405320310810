'use client'

import { useGetHomePageCompaniesQuery } from '@/app/globalRedux/api/homePageSlice'
import { RootState } from '@/app/globalRedux/store'
import useLoginRouter from '@/app/hooks/useLoginRouter'
import { useTranslation } from '@/app/i18n/client'
import { CompanyData } from '@/app/types/companyProfileTypes'
import { Button } from '@/components/ui/button'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CustomErrorBoundary from '../../ErrorBoundary'
import CompanyOutletCard from './CompanyOutletCard'
import CompaniesCardSkeleton from '../Skeletons/CompaniesCardSkeleton'

const CompaniesOutlet = () => {
  const { t } = useTranslation()
  const navigateToLogin = useLoginRouter()
  const userName = useSelector((state: RootState) => state.user.userName)
  const { loggedIn } = useSelector((state: RootState) => state.user)
  const { data: companyList = { companies: [] }, refetch: refetchCompany } =
    useGetHomePageCompaniesQuery<{
      data: { status?: string; companies: CompanyData[] }
    }>({})
  useEffect(() => {
    refetchCompany()
  }, [loggedIn])

  return (
    <div className="flex flex-col w-[409px] ml-[26px] rounded-sm mobile:ml-[0px] mobile-width">
      <div className="w-full flex justify-between items-center">
        <div className="text-[20px] py-4 font-ns_bold text-[#222222]">
          {t('Top Companies')}
        </div>
      </div>
      {companyList?.companies?.length <= 0 ? (
        <div className=" h-[954px] shadow-[0_0_6px_0_rgba(0,0,0,0.16)] w-full">
          {Array.from({ length: 10 }, (_, index) => (
            <CompaniesCardSkeleton key={index + 'company_card_skeleton'} />
          ))}
        </div>
      ) : (
        <div className="flex flex-col shadow-[0_0_6px_0_rgba(0,0,0,0.16)] rounded-[3px] mobile:shadow-[none] mobile:border ">
          <div className="w-full">
            {companyList?.companies?.length > 0 &&
              companyList?.companies?.map((companyData, index) => (
                <CustomErrorBoundary key={index}>
                  <CompanyOutletCard key={index} companyData={companyData} />
                </CustomErrorBoundary>
              ))}
          </div>
          {!userName && (
            <div className="flex justify-center items-center p-8 pt-0 mobile:px-2">
              <Button
                variant="secondary"
                size="sx"
                className="w-full py-4 text-md cursor-pointer"
                onClick={() => {
                  navigateToLogin('signup')
                }}
              >
                {t('REGISTER YOUR COMPANY')}
              </Button>
            </div>
          )}
        </div>
      )}
      <div className="mobile:hidden"></div>
    </div>
  )
}

export default CompaniesOutlet
