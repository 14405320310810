'use client'
import { AdBannerScheduleType } from '@/app/types/adType'
import { getAdsIndices, handleAdClick } from '@/app/utils/AdsUtility'
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/carousel'
import React, { useEffect, useRef, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import Image from 'next/image'
import { useSelector } from 'react-redux'
import { RootState } from '@/app/globalRedux/store'
import { useParams } from 'next/navigation'
import {
  handleAdIntersection,
  useIntersectionObserver,
} from '@/app/hooks/useIntersectionObserver'

interface PositionOneCarouselProps {
  adList?: AdBannerScheduleType[]
}

const PositionOneCarousel: React.FC<PositionOneCarouselProps> = ({
  adList,
}) => {
  const params = useParams<{ lng: string }>()

  const localeLangauge = useSelector(
    (state: RootState) => state.user.localeLangauge
  )
  const [carouselApi, setCarouselApi] = useState<CarouselApi>()
  const [currentCarouselItemIndex, setCurrentCarouselItemIndex] = useState(0)
  const [carouselItemsCount, setCarouselItemsCount] = useState(0)

  const [indicesForAdPositionOne, setIndicesForAdPositionOne] = useState<
    number[]
  >([])
  const [adsForPositionOne, setAdsForPositionOne] = useState<
    AdBannerScheduleType[]
  >([])

  const handleCarouselMapperClick = (index: number) => {
    if (!carouselApi) return
    carouselApi.scrollTo(index)
    setCurrentCarouselItemIndex(index)
  }
  useEffect(() => {
    if (!carouselApi) return
    setCarouselItemsCount(carouselApi.scrollSnapList().length)
    carouselApi.on('select', () => {
      setCurrentCarouselItemIndex(carouselApi.selectedScrollSnap())
    })
  }, [carouselApi])
  useEffect(() => {
    if (adList) {
      setIndicesForAdPositionOne(getAdsIndices(adList, 'Ad Position 1'))
    }
  }, [adList])
  useEffect(() => {
    if (adList) {
      setAdsForPositionOne(indicesForAdPositionOne?.map((ind) => adList[ind]))
    }
  }, [indicesForAdPositionOne])
  const adPositionOneRef = useRef<HTMLDivElement>(null)

  const observeElement = useIntersectionObserver(handleAdIntersection)
  useEffect(() => {
    if (adPositionOneRef.current) {
      observeElement(adPositionOneRef.current)
    }
  }, [adPositionOneRef, observeElement])

  return (
    <div className="text-center flex relative ">
      <div className="flex w-[626px] h-[300px] cursor-pointer rounded-[3px] overflow-hidden mobile-width mobile:h-[200px] banner-carousel">
        {adsForPositionOne?.length > 0 ? (
          <Carousel
            setApi={setCarouselApi}
            opts={{
              align: 'start',
              loop: true,
            }}
            plugins={[
              Autoplay({
                delay: 2400,
                stopOnInteraction: false,
              }),
            ]}
            className="w-[626px] h-[307px] mobile:h-[200px]"
          >
            <CarouselContent className="mx-0 w-[626px] h-[300px]">
              {adsForPositionOne.map((ad, index) => (
                <CarouselItem key={index} className="px-0 w-[626px] h-[307px]">
                  <div
                    ref={adPositionOneRef}
                    data-ad-position="Ad Position 1"
                    data-ad-id={ad?.ad_banner?.id}
                    data-ad-sid={ad?.id}
                    data-ad-page={ad?.page?.name}
                    data-ad-company-id={ad?.ad_banner?.company?.id}
                    data-ad-lang={params?.lng}
                  >
                    <Image
                      loading="lazy"
                      width={626}
                      height={307}
                      src={ad?.ad_banner?.image}
                      alt=""
                      className="object-fill w-full h-full mobile-width"
                      onClick={() => handleAdClick(ad, params?.lng)}
                    />
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            {/* Carousel Dots */}
            <div className="flex absolute flex-col left-7 bottom-10">
              <div className="flex items-start gap-[6px]">
                {carouselItemsCount > 1 &&
                  [...Array(carouselItemsCount)].map((_, index) => (
                    <div
                      key={index}
                      className={
                        currentCarouselItemIndex === index
                          ? 'shadow-md w-[12px] h-[12px] bg-sickly_green transition-all ease-in-out delay-150 scale-125 rounded-[10px]'
                          : 'shadow-md w-[12px] h-[12px] bg-[#ffffff] transition-all ease-in-out delay-200 rounded-[10px]'
                      }
                      onClick={() => handleCarouselMapperClick(index)}
                    ></div>
                  ))}
              </div>
            </div>
          </Carousel>
        ) : (
          <Image
            loading="lazy"
            width={626}
            height={307}
            className="object-fill"
            src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad1_626x300.png`}
            alt=""
          />
        )}
      </div>
    </div>
  )
}

export default PositionOneCarousel
