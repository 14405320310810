export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return '-'; 
    }
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  export const formatStartEndDate = (start_date: string, end_date: string) => {
    const formattedStartDate = formatDate(start_date);
    const formattedEndDate = formatDate(end_date);
  
    if (formattedStartDate === '-' || formattedEndDate === '-') {
      return '-';
    }
  
    return `${formattedStartDate} - ${formattedEndDate}`;
  };
  