import { t } from 'i18next'
import Image from 'next/image'
import { EventItem } from '../../../../app/types/eventType'
import { formatStartEndDate } from '../../../../lib/FormatStartEndDate'
import CountryTag from '../../components/CountryTag'

interface EventCardProps {
  eventData: EventItem
  isHomePage?: boolean
}

const EventsOutletCard = ({
  eventData,
  isHomePage = false,
}: EventCardProps) => {
  return (
    <div className="w-full mobile:w-[300px] cursor-pointer relative p-2 overflow-hidden bg-border-lightorange_border ">
      <div className="flex justify-between gap-4 mobile:gap-[10px]">
        {/* Image container */}
        <div
          className={`col-span-1 relative w-[100px] h-[110px] border rounded-sm mobile:w-[100px] mobile:h-[100px]`}
        >
          <Image
            loading="lazy"
            width={100}
            height={110}
            className="object-cover w-full h-full rounded-sm"
            src={
              eventData?.images?.length > 0
                ? eventData?.images[0].url
                : '/assets/Icons/noImage/noImage2.svg'
            }
            alt={eventData?.name || t('No Image Available')}
          />
        </div>
        <div className="w-full font-normal ">
          <div className="flex justify-between items-center">
            <h2
              className={`text-[18px] pb-1 mobile:text-[16px] font-ns_bold text-wrap ${isHomePage ? 'w-[80%]' : 'w-[70%]'} font-ns_bold text-wrap `}
            >
              {eventData?.name}
            </h2>
          </div>

          {/* Country and Date */}
          <div className="flex flex-row gap-3 mb-4">
            <CountryTag name={eventData?.country?.name} size="xs" />
            <div
              className={`${isHomePage ? 'text-xs' : 'text-sm'} text-[#71bb31a] mobile:text-[12px] text-default-style`}
            >
              {eventData?.start_date || eventData?.end_date
                ? formatStartEndDate(eventData?.start_date, eventData?.end_date)
                : t('date not available')}
            </div>
          </div>

          {/* Event Details */}
          <div className="">
            {eventData?.address && (
              <div className="text-default-style font-semibold mb-1">
                {eventData?.address}
              </div>
            )}

            {eventData?.organizer?.name && (
              <div className="text-default-style text-[#4e4e4e] mb-1">
                <label className="font-semibold">{t('Organizer')}:</label>{' '}
                {eventData?.organizer?.name}
              </div>
            )}

            {eventData?.organizer?.address && (
              <div className="text-default-style w-[85%] text-[#4e4e4e] mb-1">
                <label className="font-semibold">{t('Address')}:</label>{' '}
                {eventData?.organizer?.address}
              </div>
            )}

            {eventData?.mobile && (
              <div className="text-default-style text-[#4e4e4e] mb-1">
                <label className="font-semibold">{t('Ph')}:</label>{' '}
                {eventData?.mobile}
              </div>
            )}

            {/* Email and Website */}
            <div className="flex flex-row gap-x-5 gap-y-1 flex-wrap">
              {eventData?.website && (
                <div className="text-[#4e4e4e]">
                  <label className="text-[#71bb31a]">{t('Web')}:</label>{' '}
                  <a
                    href={eventData?.website}
                    target="_blank"
                    className="text-secondary hover:underline"
                  >
                    <span className="text-secondary">
                      {eventData?.website?.startsWith('http')
                        ? eventData?.website?.split('//')?.slice(1)?.join('')
                        : eventData?.website}
                    </span>
                  </a>
                </div>
              )}
              {eventData?.email && (
                <div>
                  <label className="text-[#4e4e4e]">{t('E-mail')}:</label>
                  <a
                    className="text-secondary pl-1  hover:underline"
                    href={`mailto:${eventData?.email}`}
                    target="_blank"
                  >
                    {eventData?.email}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventsOutletCard
