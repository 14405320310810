import { apiSlice } from './apiSlice'
export const homePageSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHomePageAlerts: builder.query({
      query: () => `/buyer_alerts/fetch`,
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getHomePageSales: builder.query({
      query: () => `/sales_offers/fetch`,
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getHomePageNews: builder.query({
      query: () => `/news/fetch`,
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getHomePageCompanies: builder.query({
      query: () => `/companies/fetch`,
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getHomePageEvents: builder.query({
      query: () => `/events`,
      transformResponse: async (responseData) => {
        return responseData
      },
  }),
  }),

})

export const {
  useGetHomePageAlertsQuery,
  useGetHomePageCompaniesQuery,
  useGetHomePageSalesQuery,
  useGetHomePageNewsQuery,
  useGetHomePageEventsQuery
} = homePageSlice
