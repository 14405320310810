import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/(RouteBlockers)/AuthorizationWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PanelOne","PanelTwo","PanelThree"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/AdsFructidor/HomePage/ContentAdsPanel.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/AdsFructidor/HomePage/LeftAdsPanel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/BuyersAlert.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/Categories.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/CompaniesOutlet/CompaniesOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/EventsOutlet/EventsOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/LoginOrSignUpModal/FeatureFlagStoreInitaitor.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/NavBar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/NewsLetter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/NewsOutlet/NewsOutlet.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/fructidor/fructidor-ui/releases/20241213160949/app/[lng]/components/SalesOffers.tsx");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241213160949/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/fructidor/fructidor-ui/releases/20241213160949/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
