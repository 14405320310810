'use client'
import Image from 'next/image'
import React from 'react'

interface CountryTagProps {
  name: string
  size?: string
}

const CountryTag: React.FC<CountryTagProps> = ({ name, size }) => {
  return (
    <div className="flex items-center gap-1 justify-center bg-[#f8f8f8] w-fit px-[10px] rounded-[30.5px] border border-solid border-[#d0d0d0]">
      <Image
        loading="lazy"
        src="/assets/Icons/globe.svg"
        alt=""
        width={12.8}
        height={12.1}
        className="w-[12.8px] h-[12.1px]"
      />
      <div
        className={`${size === 'xs' ? 'text-xs' : 'text-sm'} py-[1px] font-ns_semibold text-light_black mobile:text-[12px]`}
      >
        {name}
      </div>
    </div>
  )
}

export default CountryTag
