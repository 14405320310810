import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

const CompaniesCardSkeleton = () => {
  return (
    <div className="flex gap-3 rounded-[5px] my-6 ps-6">
      <Skeleton className="w-[71px] h-[70px] shadow-[0_3px_6px_0_rgba(0,0,0,0.16)]" />
      <div className="flex flex-col justify-between gap-1">
        <Skeleton className="w-40 h-4" />
        <Skeleton className="w-20 h-3" />
        <Skeleton className="w-40 h-4" />
      </div>
      <Skeleton className="w-24 h-5" />
    </div>
  )
}

export default CompaniesCardSkeleton
