import { Skeleton } from '@/components/ui/skeleton'
import React from 'react'

const HomeSalesOfferSkeleton = () => {
  return (
    <div className=" flex flex-row gap-4 bg-[#fdfdfd] rounded-[5px] border-[1px] border-solid border-[#ededed] p-4 relative overflow-hidden">
      <div className="flex flex-col gap-3">
        <Skeleton className="h-[108px]  w-[98px] rounded-xl" />
        <Skeleton className="h-6  w-[98px] rounded-none" />
      </div>
      <div className=" flex flex-col  w-[70%] gap-1.5">
        <Skeleton className="h-[15px]  w-60 rounded-xl" />
        <div className=" flex flex-row gap-2">
          <Skeleton className="h-4 w-20 rounded-xl" />
          <Skeleton className="h-[15px]  w-[30%] rounded-xl" />
        </div>
        <Skeleton className="h-3  w-40 rounded-xl mt-3" />
        <Skeleton className="h-3  w-40 rounded-xl " />
        <Skeleton className="h-3  w-40 rounded-xl " />
        <Skeleton className="h-3  w-40 rounded-xl" />
        <Skeleton className="h-4  w-40 rounded-xl absolute right-[-3rem] bottom-5 bg-[hsl(var(--muted))] -rotate-45 text-xs flex gap-1 px-8 over" />
      </div>
    </div>
  )
}

export default HomeSalesOfferSkeleton
