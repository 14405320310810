'use client'
import { updateLocalStorageCount } from '@/lib/utils'
import { sendGTMEvent } from '@next/third-parties/google'

export const GTMNewsDetailTracker = (
  company_id: number,
  current_news_lang: string,
  lang: string,
  news_id: string
) => {
  updateLocalStorageCount(news_id, 'news_detail_visit')

  sendGTMEvent({
    event: 'news_detail_visit',
    news_id: news_id,
    company_id: company_id,
    lang: lang,
    current_news_lang: current_news_lang,
  })
}

export const gtmNewsCardViewTracker = (
  newsId: string,
  companyId: string,
  lang: string,
  current_news_lang: string
) => {
  updateLocalStorageCount(newsId, 'news_card_view')

  sendGTMEvent({
    event: 'news_card_view',
    news_id: newsId,
    company_id: companyId,
    lang: lang,
    current_news_lang: current_news_lang,
  })
}
