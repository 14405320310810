'use client'
import { useGetHomePageAlertsQuery } from '@/app/globalRedux/api/homePageSlice'
import { RootState } from '@/app/globalRedux/store'
import { useTranslation } from '@/app/i18n/client'
import { AlertResponse } from '@/app/types/alertType'
import { Button } from '@/components/ui/button'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CustomErrorBoundary from '../ErrorBoundary'
import ShortListingCard from './Cards/ShortListingCard'
import ShortCardSkeleton from './Skeletons/shortCardSkeleton'
const BuyersAlert = () => {
  const { t } = useTranslation()
  const { loggedIn } = useSelector((state: RootState) => state.user)
  const params = useParams()

  const {
    data,
    isLoading,
    refetch: refetchCompanies,
  } = useGetHomePageAlertsQuery<{
    data: AlertResponse
    isLoading: boolean
  }>({})

  useEffect(() => {
    refetchCompanies()
  }, [loggedIn])

  return (
    <div className="w-full flex flex-col my-7 mobile:my-[10px]">
      <div className="text-[22px] font-ns_bold mobile:my-[10px]">
        {t('Buyers Alert')}
      </div>
      <div className="my-7 grid grid-cols-3 gap-x-5 gap-y-7 mobile:grid-cols-1 mobile:my-[10px] mobile:gap-y-[10px]">
        {!isLoading && data && data.buyer_alerts.length > 0 ? (
          data.buyer_alerts.map((item: any, index: number) => (
            <CustomErrorBoundary
              key={`Buyer_alert_card_${item.alert.id}_${index}`}
            >
              <ShortListingCard cardData={item.alert} cardType="Buyer" />
            </CustomErrorBoundary>
          ))
        ) : (
          <>
            <ShortCardSkeleton count={3} />
            <ShortCardSkeleton count={3} />
          </>
        )}
      </div>
      <div className="font-ns_bold mobile:w-full">
        <Link href={`/${params?.lng}/buyers_alert`}>
          <Button variant="secondary" size="sx" className="mobile-width">
            {t('View All')}
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default BuyersAlert
